
.sticky-header
  max-height: 90vh
  .q-table__top,
  .q-table__bottom,  
  thead tr th
    position: sticky
    z-index: 1
  thead tr:first-child th
    top: 0
  &.q-table--loading thead tr:last-child th
    top: 48px
  .q-table__bottom,  
  thead tr th:last-child
    position: sticky
    z-index: 10 !important

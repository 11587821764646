@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
body{
  font-family: $font_family;
  font-size: $font_base;
  line-height: $line_height_base;
  font-weight: 500;
}
svg{
  max-width: 100%;
}
a{
  text-decoration: none;
}
span.icon{
  box-sizing: content-box;
  line-height: 0;
  span{
    line-height: 1;
  }
  &.delete{
    color: $negative!important;
  }
}
.icon-full svg{
  width: 100%;
  height: 100%;
}
.cursor-pointer{
  cursor: pointer;
}
.text-sm{
  font-size: 15px;
}
.color-danger{
  color: $negative;
}
.q-header{
  .notification{
    position: relative;
    .icons {
      .icon{
        padding: 5px 10px;
        border-radius: $border_radius;
      }
      .icon:hover{
        background: $hover_background_color;
      }
      &.active .icon{
        background: $hover_background_color;
      }
    }
    .q-card{
      position: absolute;
      z-index: 10;
      background: #fff;
      border-radius: $border_radius;
      overflow: hidden;
      top: calc(100% + 12px);
      right: 0;
      width: 400px;
      max-width: calc(100vw - 20px);
      @media (max-width: 767px) {
        position: fixed;
        top: 60px;
        right: 10px;
      }
    }
    .notification-heading{
      div{
        display: inline-block;
        font-size: 16px;
        font-weight: 700;
      }
      a{
        font-weight: 500;
      }
    }
  }
}
.q-drawer{
  .q-item__label--header{
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 44px;
    background: linear-gradient(180deg, #53389E 0%, rgba(105, 65, 198, 0.53) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 0;
    margin-bottom: 10px;
  }
  .menu-item{
    min-height: unset;
    min-width: 40px!important;
    border-radius: $border_radius-md;
    &:hover{
      background: $hover_background_color;
    }
    &.active{
      background: $primary_light;
      color: #fff !important;
      .q-item__label{
        color: #fff !important;
      }
    }

  }
  .childs{
    .menu-item.active{
      background: #D7CDFF;
      color: $primary_dark!important;
      .q-item__label {
        color: $primary_dark !important;
      }
    }
  }
  .q-drawer__content{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &.mini-state{
      padding: 0 0 10px;
      .q-item__label--header + .q-item__label--header{
        display: block;
        text-align: center;
        margin-top: 10px;
      }
      .menu-title,
      .angle-bottom{
        display: none;
      }
      .menu-item{
        position: relative;
        padding: 8px 0;
        text-align: center;
        justify-content: center;
        min-width: 0;
        &.active{
          border-radius: 0;
        }
        &:before{
          content: "";
          position: absolute;
          left: 3px;
          top: 50%;
          border-right: 2px solid #fff;
          height: 17px;
          transform: translateY(-50%);
        }
        .q-badge{
          margin-top: 2px;
        }
      }
    }
  }
  .q-item__section--avatar{
    min-width: unset;
    padding-left: 0.25rem;
  }
}
.q-layout__section--marginal{
  background: #fff;
  color: $font_color;
}
.q-toolbar{
  padding: 10px 15px 10px 12px;
}
.user-box{
  position: relative;
  .user-name{
    cursor: pointer;
    strong{
      font-size: 14px;
    }
    p{
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
    }
    &.active{
      span.icon{
        transform: rotate(180deg);
        display: inline-block;
        margin-bottom: 3px;
      }
    }
  }
  .q-card{
    position: absolute;
    z-index: 10;
    background: #fff;
    border-radius: $border_radius;
    overflow: hidden;
    top: calc(100% + 12.5px);
    right: 0;
    width: 200px;
    a{
      display: block;
      padding: 15px 10px;
      text-decoration: none;
      color: inherit;
      font-weight: 500;
      font-size: 14px;
      & + a{
        border-top: 1px solid $border_color
      }
      &:hover{
        background-color: $hover_background_color;
      }
    }
  }
}
.q-page{
  padding: 25px 15px;
  background: $page_background;
  .q-toolbar__title{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    .breadscrumb{
      font-size: 15px;
      margin-left: 40px;
      font-weight: 400;
      .icon{
        margin-right: 20px;
        svg{
          vertical-align: middle;
        }
      }
    }
    @media(max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      .breadscrumb{
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}
.q-btn{
  width: fit-content;
  border-radius: $border_radius;
  text-transform: unset;
  span.icon{
    line-height: 0;
  }
}
.q-checkbox{
  .q-checkbox__bg{
    border-radius: 0.15em;
    border-color: $primary;
  }
  .q-checkbox__svg{
    width: 70% !important;
    margin: auto;
  }
  .q-checkbox__inner--truthy {
    .q-checkbox__bg {
        border-color: currentColor;
      }
    }
}
.q-toggle__inner{
  width: 1.6em;
  height: 1.25em;
  .q-toggle__track {
    height: 0.61em;
    border-radius: 0.3em;
  }
  .q-toggle__thumb{
    top: 0.38em;
    left: 0.35em;
    &:after {
      background-color: #fff!important;
    }
  }
  &.q-toggle__inner--truthy{
    .q-toggle__track{
      opacity: 1;
    }
    .q-toggle__thumb{
      left: 0.75em;
    }
  }
}
.q-select-custom {
  .q-field {
    min-width: 100px;
  }
  .q-field__append {
    display: none;
  }
  .q-field__native {
    justify-content: center;
  }
}
.q-field{
  min-width: 200px;
  .q-field__label{
    bottom: calc(100% + 5px);
    top: auto;
    font-weight: 500;
    transform: none !important;
    font-size: $font_base;
    left: 0;
    color: $font_color;
  }
  &.q-field--standard{
    .q-field__control{
      padding: 0 12px;
      border: 1px solid #EDEDED;
      background: #F5F7FB;
    }
  }
  &.q-field--dense .q-field__prepend{
    padding-right: 10px;
  }
  &.q-field--labeled{
    padding-top: 30px;
    &.q-field--dense .q-field__native{
      padding-top: 3px;
      padding-bottom: 4px;
    }
    .q-field__native{
       padding-top: 13px;
       padding-bottom: 16px;
     }
  }
  &.q-field--filled{
    .q-field__control{
      background: #EBEBEB;
    }
    &.q-field--error{
      .q-field__control{
        border: 1px solid currentColor;
      }
    }
  }
  &.q-field--outlined{
    .q-field__control{
      border: 1px solid $border_color;
      background: #fff;
    }
    .q-field__bottom{
      padding-left: 0;
      padding-right: 0;
    }
  }
  &.q-field--focused,
  &.q-field--error{
    .q-field__control{
      border-color: currentColor;
    }
  }
  .q-field__control{
    border-radius: 5px;
    &:before,
    &:after{
      display: none;
    }
    &:hover{
      border-color: currentColor;
    }
  }
  .q-field__control-container{
    position: static;
  }
  span.icon{
    line-height: 0;
    span{
      line-height: 0;
    }
  }

  &.q-select{
    &.q-field--labeled .q-field__control-container{
      padding-top: 0;
    }
  }
  .q-field__native[required] + .q-field__label{
    &:after{
      content: "*";
      display: inline-block;
      margin-left: 4px;
      color: $negative;
    }
  }
  input::placeholder{
    color: currentColor!important;
  }
}
.q-btn-group{
  border-radius: 8px;
  box-shadow: none;
  background-color: #F7F6F1;
  .q-btn{
    padding: 8px 30px;
  }
}
.q-table__select{
  padding-top: 0;
  min-width: auto;
}
.q-pagination{
  .q-btn{
    border-radius: 50%;
  }
}
.q-table{
  tbody td{
    height: unset;
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 1.5;
    vertical-align: middle;
    font-size: $font_base;
  }
  thead tr th{
    font-weight: 700;
    vertical-align: middle;
    font-size: $font_base;
  }
}
.q-card__actions .q-btn {
  padding: 0 20px;
}
.q-card__actions--horiz > .q-btn-item + .q-btn-item{
  margin-left: 20px;
}
.user-input .q-field__native.row{
  flex-wrap: nowrap;
}
table{
  border-collapse: collapse;
  td, th{
    border: 1px solid $border_color;
    padding: 10px 1rem;
    line-height: 1.5;
  }
}
#history-table .q-table {
  border-top: 0.5px solid #e0e0e0;
  border-left: 0.5px solid #e0e0e0;
  border-right: 0.5px solid #e0e0e0;
}
.limit-line-1{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.limit-line-3{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.data-table{
  box-shadow: none;
  thead tr{
    color: rgb(255, 255, 255);
    background-color: $primary;
  }
  &.sticky-last th:last-child,
  &.sticky-last td:last-child,
  & th.sticky-right,
  & td.sticky-right{
    position: sticky;
    right: 0;
    z-index: 1
  }
  &.sticky-last td:last-child,
  & tr td.sticky-right{
    background: #fff;
  }
  .q-table__progress .q-linear-progress{
    color: #fff!important;
  }
  @media(max-width: 599px) {
    td.text-center,
    td.text-right{
      text-align: left;
    }
    td{
      white-space: normal;
    }
    .q-table__control > span{
      display: none;
    }
  }
}
.input-message .q-field__control {
  max-height: 100px;
}
#box-message .q-message-text {
  white-space: pre-line;
}
#box-message .q-message-stamp {
  font-size: 9px;
}
#box-message .q-message-text--sent, #box-message .q-message-text--received {
  max-width: 500px;
}
#box-message .q-message-text--sent {
  color: #727bfe
}
#box-message .q-message-text--received {
  color: #f6f6f6
}
#loans-table th, #loans-table td {
  padding: 7px 8px;
}

.transaction-dialog {
  width: 400px !important;
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.lg-download {
  display: none;
}

.remove-pointer .q-table tbody tr {
  cursor: default !important;
}

.dialog-export .q-dialog__inner {
  justify-content: right !important;
}

.q-dialog .q-btn {
  margin-bottom: 5px;
}
.push-notify{
  padding: 0;
  overflow: hidden;
}
.push-notify .q-notification__icon--additional {
  margin-right: 0;
  display: block;
  padding: 15px;
  background: $primary;
}
.push-notify .q-notification__message {
  padding: 5px 20px;
}
.push-notify .q-notification__message div{
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.push-notify .q-notification__message .q-notification__caption{
  font-weight: 400;
  margin-bottom: 0;
}
.input-login{
  height: 38px !important;
}

.bg-green-custom-1 {
  background-color: #a9d5ab !important;
}
.bg-red-custom-1 {
  background-color: #f39d97 !important;
}
.text-orange-custom-1 {
  color: #f48536 !important;
}
// Quasar SCSS (& Sass) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

// Color
$primary: #685ECD;
$secondary: #18C0A2;
$warning: #F06E70;
$primary_dark: #4D43B1;
$primary_light: #877BFF;
$font_color: #333;
$placeholder_color: #999999;
$border_color: #DCDCDC;
$hover_background_color: #e8e8e8;
$page_background: #f5f5f5;
$accent: #9c27b0;

// Font
$font_family: 'Quicksand';
$font_small: 11px;
$font_base: 14px;
$font_medium: 15px;
$font_large: 18px;
$font_extra-large: 24px;
$line_height_base: 1.25;

// Base
$border_radius: 5px;
$border_radius-md: 10px;

$positive: #21ba45;
$negative: #E0413C;
$info: #31ccec;
$warning: #f2c037;

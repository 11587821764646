<template>
    <q-select
      :label="label"
      v-model="model"
      :options="filteredOptions"
      :error="!!errors.length"
      class="user-input"
      :class="extraClass"
      @filter="filter"
      use-input
      emit-value
      map-options
      dense
      :multiple="multiple"
    >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
            <q-item-section class="option">
              <q-item-label class="ellipsis q-chip-select">
                {{ scope.opt.label }}
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>

        <template v-if="!multiple" v-slot:selected-item="scope">
          <div class="ellipsis width">
            {{scope.opt.label || (!!model ? nonExistentLabel : '')}}
          </div>
        </template>

        <template v-slot:error>
            {{errors[0]}}
        </template>

        <template v-slot:append>
            <q-icon
                    v-if="(multiple && model.length) || (!multiple && !!model)"
                    class="cursor-pointer"
                    name="clear"
                    size="14px"
                    @click.stop.prevent="model = ''"
            />
        </template>
      <template v-slot:label>
        <div class="row"><span>{{ label }}&nbsp;</span><span v-if="required" class="text-red">*</span></div>
      </template>
    </q-select>
</template>

<script setup lang="ts">
  import {defineProps, ref, defineEmits, computed, watch, onMounted} from 'vue';
  import {useGetListUserOptions} from 'src/services/domains/employees/useGetListUserOptions';

  const props = defineProps({
    modelValue: {
      type: [String, Number, Array],
      default: ''
    },
    label: {
      type: String,
    },
    errors: {
      type: Array,
      default: () => []
    },
    class: {
      type: String,
      default: ''
    },
    disabledUsers: {
      type: Array,
      default: () => []
    },
    showDepartment: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    nonExistentLabel: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
  });
  const emit = defineEmits(['update:modelValue']);
  const model = computed({
    get: () => {
      if (props.multiple) {
        return props.modelValue ? Object.values(props.modelValue).map((item: string) => parseInt(item)) : [];
      }
      return parseInt(props.modelValue);
    },
    set: (value) => {return emit('update:modelValue', value)}
  });
  const {userOptions} = useGetListUserOptions(props.showDepartment);
  const mapUserOptions = computed(() => {
    return userOptions.value.map(user => {
      return {
        ...user,
        disable: !!props.disabledUsers.length && props.disabledUsers.includes(user.value)
      }
    })
  });
  const filteredOptions = ref([]);
  const filter = (val: string, update) => {
    update(() => {
      filteredOptions.value = mapUserOptions.value.filter(option => {
        return !val || option.label.toLowerCase().includes(val.toLocaleLowerCase())
      });
    })
  };
  const extraClass = ref(props.class);

  onMounted(() => {
    filteredOptions.value = mapUserOptions.value;
  });

  watch(() => mapUserOptions.value,
    (val) => {
        filteredOptions.value = val;
    });
</script>

<style scoped>
.option{
    width: 100px;
}
.width {
  flex: 0 0 100%;
  max-width: max-content;
}
</style>
